@layer modules, ui, base;
@layer base {
  .CcCheckboxFilter_labelContainer__p1lHs {
  display: flex;
  flex-direction: column;
}

.CcCheckboxFilter_subLabel__YuUzi {
  color: var(--sk-color-grey-600);
}

.CcCheckboxFilter_count__gjHXc {
  margin-left: var(--sk-space-8);
  color: var(--sk-form-checkbox-secondary-label-color-default-light);
  white-space: nowrap;
}

.CcCheckboxFilter_labelContainer__p1lHs:hover .CcCheckboxFilter_count__gjHXc {
  color: var(--sk-form-checkbox-secondary-label-color-hover-light);
}

.CcCheckboxFilter_main___FsDJ:focus-within .CcCheckboxFilter_count__gjHXc {
  color: var(--sk-form-checkbox-secondary-label-color-focus-light);
}

}
@layer base {
  .CcTag_main___dg_N {
  --border-width: 1px;
  display: flex;
  align-items: center;
  padding: 4px var(--sk-space-16);
  border: var(--border-width) solid var(--sk-color-grey-200);
  border-radius: var(--sk-radius-12);
  background: var(--sk-color-white);
  gap: var(--sk-space-4);
}

.CcTag_main___dg_N:hover {
  background: var(--sk-color-grey-050);
  cursor: pointer;
}

.CcTag_main___dg_N:focus {
  outline: 2px solid var(--sk-color-grey-200);
}

.CcTag_label__naGGR {
  flex: 0 1 auto;
  overflow: hidden;
  font-family: var(--sk-font-families-poppins);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CcTag_count__BLwUF {
  flex-shrink: 0;
  color: var(--sk-color-grey-500);
}

.CcTag_main--selected__UHWMJ {
  border: var(--border-width) solid var(--sk-color-grey-900);
  background: var(--sk-color-grey-900);
  color: var(--sk-color-white);
}

.CcTag_main--selected__UHWMJ:hover {
  background: var(--sk-color-black);
  cursor: pointer;
}

}
@layer base {
  .CcTagsFilter_main__8iuyO {
  --gap-size: var(--sk-space-8);
  display: flex;
  gap: var(--gap-size);
  flex-wrap: wrap;
}

.CcTagsFilter_ccTag__o_jyE {
  max-width: calc(50% - calc(var(--gap-size) / 2));
}

.CcTagsFilter_link__LjwmB {
  padding-top: var(--sk-space-4);
  padding-bottom: var(--sk-space-4);
}

}
@layer base {
  .SecondaryFiltersCcSection_ccSectionHeader__0EIaa {
  display: flex;
  align-items: center;
  gap: var(--sk-space-12);
  margin-bottom: var(--sk-space-32);
}

.SecondaryFiltersCcSection_ccFiltersContainer__Zlw4o {
  --cc-filters-gap: var(--sk-space-16);
  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--cc-filters-gap);
  margin-top: var(--sk-space-24);
  margin-bottom: var(--sk-space-40);
}

.SecondaryFiltersCcSection_targetedJobsFilter__bscr9,
.SecondaryFiltersCcSection_partnerJobsFilter__epY4I,
.SecondaryFiltersCcSection_alumniJobsFilter__tedgE {
  width: 100%;
}

@media (min-width: 768px) {
  .SecondaryFiltersCcSection_targetedJobsFilter__bscr9,
  .SecondaryFiltersCcSection_partnerJobsFilter__epY4I,
  .SecondaryFiltersCcSection_alumniJobsFilter__tedgE {
    width: calc(50% - calc(var(--cc-filters-gap) / 2));
  }
}

.SecondaryFiltersCcSection_section__FpcxA {
  margin-top: var(--sk-space-24);
}

.SecondaryFiltersCcSection_ccTagsFilter__DOXO0 {
  margin: var(--sk-space-24) 0;
}

.SecondaryFiltersCcSection_divider__68vI0 {
  margin: var(--sk-space-32) 0 var(--sk-space-48);
}

}
@layer base {
  .Stepper_main__hGlcq {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Stepper_step__tyR2C {
  width: var(--sk-space-40);
  height: var(--sk-space-8);
}

.Stepper_firstStep___rkJ6 {
  margin-right: var(--sk-space-4);
  border-top-left-radius: var(--sk-radius-rounded);
  border-bottom-left-radius: var(--sk-radius-rounded);
}

.Stepper_secondStep__b6SqR {
  border-top-right-radius: var(--sk-radius-rounded);
  border-bottom-right-radius: var(--sk-radius-rounded);
}

.Stepper_currentStep__EKVNn {
  background-color: var(--sk-color-green-500);
}

.Stepper_completedStep__Gr4PG {
  background-color: var(--sk-color-green-200);
}

.Stepper_futureStep__fmxVN {
  background-color: var(--sk-color-grey-100);
}

}
@layer base {
  .Modal_backdrop__EIS1E {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
  animation: 0.3s ease-out 0s 1 Modal_show-backdrop__xi6A8;
  opacity: 0.4;
  background-color: var(--sk-color-black);
}

.Modal_backdrop___fullscreen__BuJ7P {
  animation-name: Modal_show-modal__y0Lup;
  opacity: 1;
  background-color: var(--sk-color-white);
}

.Modal_wrapper__sjYyc {
  display: flex;
  position: fixed;
  z-index: var(--z-index-modal); /* above the backdrop because it is afterwards in the HTML */
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  overflow-y: hidden;
  animation: 0.3s ease-out 0s 1 Modal_show-modal__y0Lup;
  background-color: var(--sk-color-white);
}

.Modal_wrapper__isLocked__w51k6 {
  pointer-events: none;
}

@media (min-width: 768px) {
  .Modal_wrapper__sjYyc {
    top: 50%;
    right: inherit; /* reset mobile */
    bottom: inherit; /* reset mobile */
    left: 50%;
    max-width: 35.25rem; /* 564px */
    height: auto; /* reset mobile */
    max-height: 75vh; /* around 640px when the window height is 834px */
    transform: translate(-50%, -50%);
    border-radius: var(--sk-radius-24);
  }

  .Modal_wrapper___enlarged__q8zy9 {
    max-width: 48rem;
    max-height: 90vh;
  }

  .Modal_wrapper___fullscreen__N_eUo {
    max-width: inherit;
    height: 100vh;
    max-height: inherit;
    border-radius: 0;
  }
}

.Modal_head__wabsP {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: var(--sk-space-32);
  padding: 0 var(--sk-space-16);
  background-color: var(--sk-color-white);
}

.Modal_head___withSubtitle__jzKNx {
  align-items: flex-start;
}

@media (min-width: 768px) {
  .Modal_head__wabsP {
    padding: 0 var(--sk-space-32);
  }
}

.Modal_closeButton__vUyEQ {
  margin-left: auto;
}

.Modal_wrapper___withoutHeader__7ficA .Modal_closeButton__vUyEQ {
  position: absolute;
  top: var(--sk-space-16);
  right: var(--sk-space-16);
}

@media (min-width: 768px) {
  .Modal_wrapper___withoutHeader__7ficA .Modal_closeButton__vUyEQ {
    top: var(--sk-space-24);
    right: var(--sk-space-24);
  }

  .Modal_wrapper___fullscreen__N_eUo.Modal_wrapper___withoutHeader__7ficA .Modal_closeButton__vUyEQ {
    top: var(--sk-space-8);
    right: var(--sk-space-8);
  }
}

.Modal_content__BD_Zi {
  flex: 1 1 auto;
  padding: 0 var(--sk-space-16);
  overflow-y: auto;
}

@media (min-width: 768px) {
  .Modal_content__BD_Zi {
    min-height: var(--sk-space-48);
    padding: var(--sk-space-32);
  }

  .Modal_subtitle__azSFY {
    margin-top: var(--sk-space-8);
  }
}

.Modal_wrapper___withOverflow__0G7Pn,
.Modal_wrapper___withOverflow__0G7Pn .Modal_content__BD_Zi {
  overflow-y: visible;
}

.Modal_wrapper___withoutHeader__7ficA .Modal_content__BD_Zi {
  margin-top: var(--sk-space-32);
}

@media (min-width: 768px) {
  .Modal_wrapper___withoutHeader__7ficA .Modal_content__BD_Zi {
    margin-top: 0;
    padding-top: var(--sk-space-40);
  }
}

.Modal_foot__cNOUj {
  display: flex;
  justify-content: flex-end;
  padding: var(--sk-space-24) var(--sk-space-16) var(--sk-space-32);
  background-color: var(--sk-color-white);
  /* Shadow is --sk-color-grey-600 with 16% opacity */
  box-shadow: 0 var(--sk-space-4) var(--sk-space-16) 0 rgb(100 96 108 / 15%);
}

@media (min-width: 768px) {
  .Modal_foot__cNOUj {
    padding: var(--sk-space-24) var(--sk-space-32) var(--sk-space-32);
  }
}

@keyframes Modal_show-modal__y0Lup {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes Modal_show-backdrop__xi6A8 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.4;
  }
}

}
@layer base {
  .JoinMyCareerCenter_main__eKEpe {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
  text-align: center;
}

.JoinMyCareerCenter_logos__yJPUF {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--sk-space-16);
}

.JoinMyCareerCenter_logos__yJPUF img {
  border-radius: var(--sk-radius-4);
}

.JoinMyCareerCenter_content__loe4g {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.JoinMyCareerCenter_actions__yj2Bu {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--sk-space-16);
  justify-content: center;
  margin-top: var(--sk-space-8);
}

@media (min-width: 768px) {
  .JoinMyCareerCenter_actions__yj2Bu {
    flex-direction: row;
  }
}

.JoinMyCareerCenter_btn__loading__XiEsy {
  background-color: var(--sk-action-button-color-background-highlight-disabled);
  color: var(--sk-action-button-color-highlight-disabled);
  pointer-events: none;
}

}
@layer base {
  .FileInput_main__VSeMh {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.FileInput_label__Qnvlj {
  text-align: left;
}

.FileInput_content__VJvTu,
.FileInput_previewWrapper__vqjDe {
  position: relative;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
}

/* Field */
.FileInput_field__EaVg9 {
  display: none;
  text-align: center;
}

.FileInput_field___visible__z3Nfs {
  display: block;
}

.FileInput_field___dragOver__8gjL_ {
  background-color: var(--sk-color-grey-100);
}

.FileInput_field__EaVg9 .FileInput_notice__SzB16 {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-grey-600);
}

.FileInput_field__EaVg9 .FileInput_error__j_LnS {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

.FileInput_field__EaVg9 .FileInput_uploadButton__xGE5n {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  width: 100%;
  margin-top: var(--sk-space-24);
}

/* This prevents to drop a file on the button */
.FileInput_field___dragOver__8gjL_ .FileInput_uploadButton__xGE5n {
  z-index: var(--z-reset-in-the-current-stacking-context);
}

/* The input needs to be above the whole component to accept dropped files */
.FileInput_field__EaVg9 .FileInput_uploadButton__input__Ceu6b {
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* Preview */
.FileInput_preview__yFNBs {
  display: flex;
  align-items: center;
  outline: none;
  color: inherit;
  text-decoration: none;
}

.FileInput_content__VJvTu > .FileInput_previewWrapper__vqjDe {
  position: initial;
  padding: 0;
}

.FileInput_preview__yFNBs::after {
  content: "";
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  inset: 0;
}

.FileInput_preview__yFNBs:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-color-grey-200) 2px solid;
}

.FileInput_preview__yFNBs:focus-visible::after {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);

  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.FileInput_preview__yFNBs .FileInput_description__ETdhG {
  margin-right: auto;
}

.FileInput_preview__yFNBs .FileInput_actions__lJIpB {
  display: flex;
  align-items: center;
  gap: var(--sk-space-8);
}

.FileInput_preview__yFNBs .FileInput_deleteButton__PLCU_ {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  color: var(--sk-color-error-500);
}

}
@layer base {
  .RegistrationDetails_status__YPi1t {
  position: absolute;
  bottom: 0;
  color: var(--sk-color-grey-100);
  font-weight: bold;
  line-break: anywhere;
}

/* necessary for the skeleton to be exactly the desired height */
/* https://github.com/dvtng/react-loading-skeleton/issues/23#issuecomment-939231878 */
.RegistrationDetails_registrationDetailsPlaceholder__PXtu3 {
  display: block;
  line-height: 1;
}

}
@layer base {
  .RegistrationReminder_main__P8tQ_ {
  margin-top: var(--sk-space-24);
}

}
@layer base {
  .SpotsLeft_spots__P6clM {
  margin-top: var(--sk-space-24);
}

}
@layer base {
  .StatusConfirmed_main__PaCrt {
  margin-top: var(--sk-space-24);
}

.StatusConfirmed_textInfo__WnyHT {
  font-size: var(--sk-font-size-1);
}

}
@layer base {
  .StatusDeclined_main__NRf6Q {
  margin-top: var(--sk-space-24);
}

}
@layer base {
  .StatusUserNotAvailable_main__yc6Nk {
  display: flex;
  margin-top: var(--sk-space-16);
  color: var(--sk-color-grey-600);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
}

.StatusUserNotAvailable_icon__6JBlp {
  margin-top: var(--sk-space-4);
  line-height: var(--sk-line-heights-3);
}

.StatusUserNotAvailable_text__CNT_l {
  margin-left: var(--sk-space-4);
  font-size: var(--sk-font-size-1);
  line-height: var(--sk-line-heights-3);
  vertical-align: middle;
}

}
@layer base {
  .StatusWarning_main__CKuyk {
  margin-top: var(--sk-space-24);
}

}
@layer base {
  .UnregistrationReminder_main__ZVcJ6 {
  margin-top: var(--sk-space-24);
}

}
@layer base {
  @media (min-width: 768px) {
  .Unsubscribe_link__bLIgB {
    margin-top: var(--sk-space-24);
    text-align: center;
  }
}

}
@layer base {
  .PageContent_main___kmeF {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.PageContent_content__aPCOU {
  --local-row-gap: var(--sk-space-32) !important;
  position: relative;
  z-index: var(--z-reset-in-the-current-stacking-context);
  padding: var(--sk-space-40) var(--sk-space-16);
}

@media (min-width: 768px) {
  .PageContent_content__aPCOU {
    padding: var(--sk-space-64) 0;
  }
}

.PageContent_logos__FMXtY {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

@media (min-width: 768px) {
  .PageContent_logos__FMXtY {
    justify-content: center;
  }
}

/* Signup */
.PageContent_signup__Saq4S {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageContent_signup__Saq4S {
    padding: var(--sk-space-48) var(--sk-space-32);
    border-radius: var(--sk-radius-16);
    background-color: var(--sk-color-white);
    box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(0 0 0 / 25%);
    gap: var(--sk-space-40);
  }
}

.PageContent_arrow__1wb7m {
  display: none;
  pointer-events: none;
}

@media (min-width: 1312px) {
  .PageContent_arrow__1wb7m {
    display: initial;
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context);
    right: 5%;
    bottom: 15%;
    rotate: -15deg;
  }
}

.PageContent_signup__Saq4S .PageContent_title__iIchU {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

@media (min-width: 1312px) {
  .PageContent_signup__Saq4S .PageContent_title__iIchU {
    text-align: center;
  }
}

.PageContent_form__JDuaR,
.PageContent_dualFields__TyvUH {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageContent_dualFields__TyvUH {
    flex-direction: row;
    gap: var(--sk-space-16);
  }

  .PageContent_dualFields__TyvUH .PageContent_field__6c64z {
    width: 50%;
  }

  .PageContent_dualFields__TyvUH .PageContent_field__6c64z input {
    width: 100%;
  }
}

.PageContent_submitButton__95Oi2 {
  width: 100%;
}

/* Login */
.PageContent_login__sQtHj {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--sk-space-32);
}

.PageContent_login__sQtHj .PageContent_divider__vPoG_ {
  display: inline-flex;
  align-self: center;
  width: var(--sk-space-40);
}

.PageContent_login__sQtHj .PageContent_title__iIchU {
  display: flex;
  gap: var(--sk-space-8);
  justify-content: center;
}

.PageContent_loginTerms__iytnJ {
  color: var(--sk-color-grey-600);
  text-align: center;
}

}
@layer base {
  .SecondaryFiltersModal_footer__kvCcW {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 767px) {
  .SecondaryFiltersModal_footer__kvCcW {
    flex-direction: column-reverse wrap;
  }
}

.SecondaryFiltersModal_divider__aQqgl {
  margin: var(--sk-space-32) 0 var(--sk-space-48);
}

.SecondaryFiltersModal_filter__6iKV1:last-of-type {
  margin-bottom: var(--sk-space-32);
}

}
@layer base {
  .ReportModal_main__Qf_xe {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.ReportModal_actions__9nWg_ {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: var(--sk-space-8);
}

@media (min-width: 768px) {
  .ReportModal_actions__9nWg_ {
    flex-direction: row;
  }
}

}
@layer base {
  .FileQuestion_main__5sldR {
  z-index: var(--z-reset-in-the-current-stacking-context);
}

}
